const initialProjects = {
  oldProjects: [
    {
      _id: '1',
      // projectName: 'Angle Profiles',
      project_img: '/images/products/d3.jpg',
    },
    {
      _id: '2',
      // projectName: 'auxiliary-profiles',
      project_img: '/images/products/d4.jpg',
    },
    {
      _id: '3',
      // projectName: 'awning-profiles',
      project_img: '/images/products/d1.jpg',
    },
    {
      _id: '4',
      // projectName: 'box-profiles',
      project_img: '/images/products/m1.jpg',
    },
    {
      _id: '5',
      // projectName: 'glass-channel-railing-profiles',
      project_img: '/images/products/mm4.jpg',
    },
    {
      _id: '6',
      // projectName: 'interior-profiles',
      project_img: '/images/products/mm3.jpg',
    },
    {
      _id: '7',
      // projectName: 'mosquito-profiles',
      project_img: '/images/products/mm1.jpg',
    },
    {
      _id: '8',
      // projectName: 'polycarbonate-profiles',
      project_img: '/images/products/g1.jpg',
    },
    {
      _id: '9',
      // projectName: 'shower-cabin-profiles',
      project_img: '/images/products/g2.jpg',
    },
    {
      _id: '10',
      // projectName: 'shutter-profiles',
      project_img: '/images/products/g3.jpg',
    },
    {
      _id: '11',
      // projectName: 'sliding-door-profiles',
      project_img: '/images/products/mm6.jpg',
    },

    {
      _id: '16',

      project_img: '/images/products/d1.jpg',
    },
  ],

  laptopProducts: [
    {
      _id: '1',
      // projectName: 'Angle Profiles',
      project_img: '/images/products/l1.png',
    },
    {
      _id: '2',
      // projectName: 'auxiliary-profiles',
      project_img: '/images/products/l2.png',
    },
    {
      _id: '3',
      // projectName: 'awning-profiles',
      project_img: '/images/products/l3.png',
    },
    {
      _id: '4',
      // projectName: 'box-profiles',
      project_img: '/images/products/l4.png',
    },
    {
      _id: '5',
      // projectName: 'glass-channel-railing-profiles',
      project_img: '/images/products/l5.png',
    },
    {
      _id: '6',
      // projectName: 'interior-profiles',
      project_img: '/images/products/l6.jpg',
    },
    {
      _id: '7',
      // projectName: 'mosquito-profiles',
      project_img: '/images/products/l7.jpg',
    },
    {
      _id: '8',
      // projectName: 'polycarbonate-profiles',
      project_img: '/images/products/l8.jpg',
    },
   
  ],
};

export default initialProjects;
