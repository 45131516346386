import React from "react";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import ChartReact from "../components/ChartReact";
import ChartBackend from "../components/ChartBackend";
import TextAnimation from "../components/TextAnimation";

export default function AboutUs() {
  const { t } = useTranslation();
  const bannerImage = "../images/about1.jpg";
  //const about = {t('about_Us')};
  return (
    <>
      <div className="imgHeaderService d-flex justify-content-center align-items-center h-100">
        <img
          className="d-flex w-100 bg-image"
          src={bannerImage}
          alt={bannerImage}
        ></img>

        <div>
          <div className="content">
            <h6>
              <TextAnimation />
            </h6>
            {/* <strong>{t("about_Us")}</strong> */}
          </div>
        </div>
      </div>

      <div className="container aboutdiv d-flex ">
        <div className="container my-5">
          <Row>
            <Col md={3}>
              <Row>
                <h4>
                  <strong>{t("about_Company")}</strong>
                </h4>
              </Row>
              <div className="image-group"></div>
            </Col>
            <Col md={9}>
              <Row>
                <h3>{t("about_Us")}</h3>
              </Row>
              <Row>
                <p className=" text-justify">{t("try_statement")}</p>
              </Row>
            </Col>
          </Row>
        </div>
        <div></div>
      </div>
      {/* <div className="bgabout-image ">
        <Container className="d-flex">
          <Row>
            <Col sm={6}></Col>
            <Col sm={6}>
              <div className="agency-About reveal">
                <h4 className="text-justify">
                  <strong>{t('how-Help')}</strong>
                </h4>
                <div>
                  <ul>
                    <li>{t('job_one')}</li>
                    <li>{t('job_two')}</li>
                    <li>{t('job_three')}</li>
                    <li>{t('job_four')}</li>
                    <li>{t('job_six')}</li>
                    <li>{t('job_five')}</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      {/* <Container className="my-5">
        <Row>
          <Col>
            <h4 className="d-flex justify-content-center">{t('tool_use')}</h4>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center text-wrap">
            <ChartReact></ChartReact>
            <ChartBackend></ChartBackend>
          </Col>
        </Row>
      </Container> */}
    </>
  );
}
