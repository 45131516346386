import React from "react";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

export default function Product(props) {
  const { t } = useTranslation();
  const { product } = props;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Card key={product._id} className="box d-flex w-100 col-md-3 col-sm-4">
        {/* <Link
          className="text-color"
          to={`/product/${product._id}`}
          onClick={scrollToTop}
        > */}
        <img
          className="img-section"
          src={product.image}
          alt={product.projectName}
        />
        {/* <h1>kar22 kar ba</h1> */}
        {/* <div className="service-name">{t(`${product.name}`)}</div> */}
        {/* </Link> */}
      </Card>
    </div>
  );
}
