import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Work from "../components/Work";
import { Col, Row } from "react-bootstrap";
import Header from "../components/Header";
import initialProjects from "../initialProjects";
import Category from "../components/Category";
import CategoryList from "../components/CategoryList";
// import { t } from "i18next";
// import Projects from "../components/Projects";
import TeamMember from "../components/TeamMember";
// import HeaderTop from "../components/HeaderTop";
// import HeaderBottom from "../components/HeaderBottom";
// import HeroSlider from "../components/HeroSlider";

const heroSliders = [
  {
    images: "slideWrapperOne",
    title: "We Fight For Your Justice",
    subTitle: "As Like A Friend.",
    text: "The Most Talented Law Frim",
    button: "Contact us now",
  },
  {
    images: "slideWrapperTwo",
    title: "We Fight For Your Justice",
    subTitle: "As Like A Friend.",
    text: "The Most Talented Law Frim",
    button: "Contact us now",
  },
];

export default function HomeScreen() {
  const { t } = useTranslation();
  const [mydata, setdata] = useState(initialProjects);

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  return (
    <div>
      <Header />
      {/* <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header> */}
      {/* <HeroSlider sliders={heroSliders} className="heroSliderArea" /> */}
      <Work />
      <div
        id="our_products"
        className="container-fluid d-flex  container-section-first "
      >
        <div className="container my-4 ">
          <div className="col-service ">
            <h1 className="h1-title">{t("our_projects")}</h1>
            <Row>
              {mydata.length === 0 && console.log("No Categories found kaka")}

              {mydata.oldProjects.slice(0, 16).map((category, index) => (
                <Col
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="mb-3 reveal"
                >
                  <Category category={category}></Category>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
     
      {/*  */}
      <TeamMember></TeamMember>
      {/*  */}
      <div className="break-div"></div>
      <div className="container-fluid d-flex container-section">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-6 section-info">
              <h2>{t("why_chose")}</h2>
              <h2>
                {/* <h1>kart11</h1> */}
                <strong>{t("try_us")}</strong>
              </h2>
              {/* <p className="text-justify">{t('try_statement')}</p> */}
            </div>
            <div className="col-md-6 reveal">
              <img
                className="img-section"
                src="./images/products/all3.png"
                alt="./images/comp1.png"
              ></img>
            </div>
          </div>
        </div>
      </div>
      {/* <Projects /> */}
      {/* <div className="container d-flex container-section-bottom reveal">
        <div className="col-md-6 ">
          <img
            className="img-section-bottom"
            src="./images/idea.png"
            alt="./images/idea.png"
          />
        </div>
        <div className="col-md-6 section-info-bottom reveal">
          <h1 className="bottom-text">
            <strong>{t('turn_quot')}</strong>
          </h1>
        </div>
      </div> */}
    </div>
  );
}
