import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import SingleCard from '../components/SngleCard';
import TextAnimation from '../components/TextAnimation';
import initialProjects from '../initialProjects';
import CategoryList from '../components/CategoryList';
import { Col } from 'react-bootstrap';
import Category from '../components/Category';
import SingleCard2 from '../components/SingleCard2';

export default function ProjectScreen() {
  const { t } = useTranslation();
  const [mydata, setdata] = useState(initialProjects);
  const [loading, setLoading] = useState(false);

  const bannerImage = '../images/standard-banner1.jpg';

  return (
    <>
      <div className="imgHeaderService ">
        <img
          className="d-flex w-100 bg-image"
          src={bannerImage}
          alt={bannerImage}
        ></img>
        <div className="content">
          <h6>
            {/* <TextAnimation /> */}
            {/* <h2>kar 5</h2> */}
          </h6>
          {/* <p className="header-job">{t("product_page")}</p> */}
        </div>
      </div>
      <div className="container">
        <div className="my-3">
          <Row>
            <h1 className="h1-title">{t('product_page')}</h1>
          </Row>
          <Row className="flex flex-wrap justify-content-center bg-color-gray">
            <div className="powerTools">
              <h2 className="h2-title p-2">
                <ul>
                  <li>Power plant generators and Electrical transformers</li>
                </ul>
              </h2>
            </div>
            {loading ? (
              <div></div>
            ) : (
              mydata.oldProjects.map((project) => {
                return <SingleCard data={project} key={project.projectName} />;
              })
            )}
          </Row>

          <Row className="flex flex-wrap justify-content-center bg-color-gray">
            <div className="powerTools">
              <h2 className="h2-title mt-5">
                <ul>
                  <li>
                    Electrical and Electronic Appliances Spare Parts Trade
                  </li>
                </ul>
              </h2>
            </div>
            {loading ? (
              <div></div>
            ) : (
              mydata.laptopProducts.map((project) => {
                return <SingleCard2 data={project} key={project.projectName} />;
              })
            )}
          </Row>
        </div>
      </div>
    </>
  );
}
