import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
//import InstagramIcon from '@mui/icons-material/Instagram';
//import TwitterIcon from '@mui/icons-material/Twitter';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFacebook, FaInstagramSquare } from 'react-icons/fa';
//import  from '@mui/icons-material/Facebook';
import { FaArrowCircleUp } from 'react-icons/fa';

export default function Footer() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);
  // const headerImg = "../images/nsurw.png";
  const headerImg = '../images/products/buildnow.png';

  return (
    <>
      <div className="container footer-space mt-5">
        <Row>
          <Col md={4}>
            <Link to="/" onClick={scrollToTop}>
              <Navbar.Brand className="brand-footer-logo">
                <span>
                  <img className="img-icon" src={headerImg} alt={headerImg} />
                </span>
                <p className="textcolor ml-8">{t('company_statement')}</p>
              </Navbar.Brand>
            </Link>

            {/* <div>kart9999</div> */}
          </Col>
          <Col md={4}>
            <h6 className="footer-title px-1 d-flex">{t('Our-Brands')}</h6>
            <div className="d-flex my-1 p-1">
              <i class="fa fa-minus mt-1 px-1 fa-2xs"></i>
              {/* BOSCH */}
              Diesel
            </div>
            <div className="d-flex p-1 my-1">
              <i class="fa fa-minus mt-1 px-1 fa-2xs"></i>
              {/* DEWALT */}
              Gasoline
            </div>
            <div className="d-flex p-1 my-1 ">
              <i class="fa fa-minus mt-1 px-1 fa-2xs"></i>
              Natural gas
            </div>
            {/* <div className="d-flex p-1 my-1 ">
              <i class="fa fa-minus mt-1 px-1 fa-2xs"></i>
              MILWAUKEE
            </div> */}
          </Col>
          <Col md={4}>
            <h6 className="footer-title d-flex">{t('Contact_us')}</h6>
            <div className="d-flex my-1 p-1">
              <i className="fa fa-map-marker colorInfo  fa-lg"></i>
              {/* Iraq - erbil - 100m road - Opposite Family Mall */}
              Iraq - erbil - Ary
            </div>
            <div className="d-flex p-1 my-1">
              <i className="fa fa-envelope colorInfo fa-sm"></i>
              info@buildnow-iq.com
            </div>
            <div className="d-flex p-1 my-1 ">
              <i className="fa fa-phone colorInfo fa-lg"></i>
              00964 750 129 3832
            </div>
            <div className="d-flex p-1 my-1 ">
              <i className="fa fa-phone colorInfo fa-lg"></i>
              00964 773 429 3832
            </div>
          </Col>
          <Col></Col>
          {/* <Col md={2}>
            <h6 className=" footer-title d-flex">{t("follow_us")}</h6>
            <div className="media-footer">
              
              <FaFacebook size={24} className="media-footer" />
             
              <FaInstagramSquare size={24} className="media-footer" />
             
            </div>
          </Col> */}
        </Row>
      </div>
      <div className="scroll-button">
        <FaArrowCircleUp
          onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }}
        />
      </div>

      <div className="row-footer flex">
        <div className="row-line  text-center mt-5">
          <div className="footer-copy w-100">
            © 2024 Copyright: All rights reserved Designed by{' '}
            <a
              href="https://www.glossycode.com"
              target="_blank"
              rel="noreferrer"
            >
              Glossy Code
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
