const data = {
  products: [
    {
      _id: "1",
      name: "Architectural Systems",
      // image: '/images/products/Bosch.png',
      image: "/images/products/brand15.png",
      imageBranchf: ["Business Website", "Personal Website", "Ecommerce Store"],

      description: "Architectural Systems",
    },
    {
      _id: "2",
      name: "Standard Products",
      // image: "/images/products/dewalt.png",
      image: "/images/products/brand6.png",
      description: "Standard Products",
    },
    {
      _id: "3",
      name: "Composite Panels",
      image: "/images/products/brand9.png",
      description: "Composite Panels",
    },
    {
      _id: "4",
      name: "Accessories Range",
      image: "/images/products/brand14.png",
      description: "Accessories Range",
    },
  ],
};
export default data;
