import React from "react";
import { useTranslation } from "react-i18next";
import TextAnimation from "./TextAnimation";
// import headerImg from '../images/bannermain.png';

export default function Header() {
  const { t } = useTranslation();
  const headerImg = "../images/bannermain.png";
  // const headerImg = "../images/products/buildnow.png";
  return (
    <div className="imgHeader d-flex justify-content-center align-items-center">
      <img className="d-flex bg-image" src={headerImg} alt={headerImg}></img>

      <div>
        <div className="content">
          <h6>
            {/* <TextAnimation /> */}
            {/* kar2 */}
          </h6>
          {/* <p className="header-job">{t('main_title')}</p> */}
        </div>
      </div>
    </div>
  );
}
