import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import data from "../data";
import TextAnimation from "../components/TextAnimation";
import initialProjects from "../initialProjects";

export default function ProductScreen(props) {
  const { t } = useTranslation();
  const { id } = useParams();
  const product = initialProjects.oldProjects.find((x) => x._id === id);
  // const product = data.products.find((x) => x._id === id);
  if (!product) {
    return <div> Product Not Found</div>;
  }
  const bannerImage = "../images/service.jpg";

  return (
    <>
      <div className="imgHeaderService ">
        <img
          className="d-flex w-100 bg-image"
          src={bannerImage}
          alt={bannerImage}
        ></img>
        <div className="content">
          <h6>
            <TextAnimation />
          </h6>
          <strong>{t(`${product.projectName}`)}</strong>
        </div>
      </div>
      <div className="container d-flex">
        <div className="container mt-4">
          <Row>
            <h5>
              {t("service")}/{t(`${product.projectName}`)}
            </h5>
          </Row>
          <Row>
            <Col md={4}>
              <Row xs={3} md={6} className="g-1"></Row>
              <div className="image-group">
                <img
                  className="large"
                  src={product.project_img}
                  alt={product.projectName}
                ></img>
              </div>
            </Col>
            <Col md={8}>
              <Row>
                <h3>{t(`${product.projectName}`)}</h3>
                {/* <h3>kar7</h3> */}
              </Row>
              {/* <Row>
                <p className="text-justify">{t(`${product.description}`)}</p>
              </Row> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
